<template>
  <div>
    <van-nav-bar title="Test" />
    <van-cell-group inset style="margin-top:15px">
      <van-field v-model="value" label="StToken" placeholder="请输入StToken" />
    </van-cell-group>
    <van-cell-group inset title="学生端">
      <van-cell is-link clickable v-for="item,index in xsRoutes" :key="index" :title="item.meta.title" @click="toRouter(item.path)" />
    </van-cell-group>
    <van-cell-group inset title="学生处管理端">
      <van-cell is-link clickable v-for="item,index in xscRoutes" :key="index" :title="item.meta.title" @click="toRouter(item.path)" />
    </van-cell-group>
    <van-cell-group inset title="教师端">
      <van-cell is-link clickable v-for="item,index in jsRoutes" :key="index" :title="item.meta.title" @click="toRouter(item.path)" />
    </van-cell-group>
    <van-cell-group inset title="公寓">
      <van-cell is-link clickable title="公寓入住查询" @click="toRouter('/gy/g01')" />
    </van-cell-group>
    <van-cell-group inset title="访问失败">
      <van-cell is-link clickable title="统一认证失败" @click="toRouter('/401')" />
      <van-cell is-link clickable title="未检测到账号" @click="toRouter('/403')" />
    </van-cell-group>
  </div>
</template>

<script>
import { Notify } from "vant";

export default {
  name: "Index",
  data() {
    return {
      isQuery: true,
      value: "",
      xsRoutes: [],
      jsRoutes: [],
      xyRoutes: [],
      xscRoutes: [],
      gyRoutes: [],
    };
  },
  created() {
    if (this.$router.options.routes) {
      let xs = this.$router.options.routes.filter(
        (item) => item.path.startsWith("/xs") && this.filter(item.path)
      );
      if (xs.length > 0) this.xsRoutes = xs;

      let js = this.$router.options.routes.filter(
        (item) => item.path.startsWith("/js") && this.filter(item.path)
      );
      if (js.length > 0) this.jsRoutes = js;

      let xsc = this.$router.options.routes.filter(
        (item) => item.path.startsWith("/xc") && this.filter(item.path)
      );
      if (xsc.length > 0) this.xscRoutes = xsc;


      let xy = this.$router.options.routes.filter(
        (item) => item.path.startsWith("/xy") && this.filter(item.path)
      );
      if (xy.length > 0) this.xyRoutes = xy;
    }
  },
  methods: {
    toRouter(path) {
      if (this.value) {
        this.$router.push({
          path: path,
          query: { st: this.value },
        });
      } else {
        Notify({
          type: "danger",
          message: "无效Token",
          duration: 1000,
        });
      }
    },
    filter(path) {
      if (path.endsWith("form") || path.endsWith("edit") || path.endsWith("add") || path.endsWith("detail")) {
        return false;
      }
      return true;
    },
  },
};
</script>


